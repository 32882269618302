import asset1 from '../assets/favorite1.JPG'
import asset2 from '../assets/favorite2.JPG'
import asset3 from '../assets/favorite3.JPG'
import asset4 from '../assets/favorite4.JPG'
import asset5 from '../assets/favorite5.JPG'

const buy=[
    {
        id:1,
        product:'Favorites',
        image:asset1,
        description:'1'
       
    },
    {
        id:2,
        product:'Favorites',
        image:asset2,
        description:'2'
        
    },
    {
        id:3,
        product:'Favorites',
        image:asset3,
        description:'3'
        
    },
    {
        id:4,
        product:'Favorites',
        image:asset4,
        description:'4'
        
    },
    {
        id:5,
        product:'Favorites',
        image:asset5,
        description:'5'
        
    }

]
      

export default buy