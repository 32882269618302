import React from 'react'
import Massage from '../assets/lemon-906141_1280.jpg'
import Flash from 'react-reveal/Flash'
import Bounce from 'react-reveal/Bounce'
import './spar.css'
function Spar() {
    return (
        <div className="about-massage">
            <h1 style={{color:"#b48338",textAlign:"center"}}>OUR MASSAGE SERVICES</h1>
       
            <div className="massage">
            <Flash>
                <div className="massage-aside">
                    <h3>Wellness and Beauty</h3>
                    <p>Massages are not just about luxury, they are also therapeutic.
                        Our savvy massage therapists  through their expertise help you realize the full
                         health benefits of massage therapy.The treatment rooms provide 
                         an absolute relaxation,quietness,tranquility and serenity for your mind and body.
                       Our Beauty spa offers a surreal ambience with soothing smooth music and highly 
                       professional masseuses.
                        
                        </p>
                        <img src={Massage} alt="parlour"/>
                </div>
                </Flash>
                <div className="massage-all">
           
           
       </div>
            </div>
        </div>
    )
}

export default Spar
