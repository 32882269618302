import React,{useState,Fragment} from 'react'
import {RiCloseLine} from 'react-icons/ri'
import './gallery.css'

import img23 from '../assets/1.jpg'
import img24 from '../assets/2.jpg'
import img25 from '../assets/3.jpg'
import img26 from '../assets/curve2.jpg'
import img27 from '../assets/5.jpg'
import img28 from '../assets/6.jpg'
import img29 from '../assets/7.jpg'
import img30 from '../assets/8.jpg'
import img31 from '../assets/9.jpg'
import img32 from '../assets/10.jpg'
import img33 from '../assets/12.jpg'
import img34 from '../assets/13.jpg'
import img35 from '../assets/14.jpg'
import img36 from '../assets/15.jpg'
import img37 from '../assets/lads.JPG'
import img38 from '../assets/lads1.JPG'
import img39 from '../assets/lads3.JPG'
import img40 from '../assets/public.jpeg'
import img41 from '../assets/bed222.jpeg'
import img42 from '../assets/gallery2021.jpeg'
import img43 from '../assets/gallery22.jpeg'
import img44 from '../assets/gallery23.jpeg'
import img45 from '../assets/gallery24.jpeg'
import img46 from '../assets/IMG_3543.jpg'
import img47 from '../assets/IMG_3578.jpg'
const galleryData=[
    {
        id:1,
        imgSrc:img24,
       
    },
    {
        id:2,
        imgSrc:img25,
  
    },
    {
        id:3,
        imgSrc:img26,
   
    },
    {
        id:4,
        imgSrc:img23,
       
    },
    {
        id:5,
        imgSrc:img28,
     
    },
    {
        id:6,
        imgSrc:img29,
       
    },
    {
        id:7,
        imgSrc:img30,
        
    },
    {
        id:8,
        imgSrc:img31,
      
    },
    {
        id:9,
        imgSrc:img32,
    },
    {
        id:10,
        imgSrc:img33,
       
    },
    {
        id:11,
        imgSrc:img34,
       
    },
    {
        id:12,
        imgSrc:img35,
       
    },
    {
        id:13,
        imgSrc:img36,
       
    },
    {
        id:14,
        imgSrc:img37
       
    },
    {
        id:15,
        imgSrc:img38
      
    },
    {
        id:16,
        imgSrc:img39
        
    },
    {
        id:17,
        imgSrc:img40
       
    },
    {
        id:18,
        imgSrc:img41
      
    },
    {
        id:19,
        imgSrc:img42
      
    },
    {
        id:20,
        imgSrc:img43
      
    },
    {
        id:21,
        imgSrc:img44
      
    },
    {
        id:22,
        imgSrc:img45
      
    },
    {
        id:23,
        imgSrc:img46
      
    },
    {
        id:24,
        imgSrc:img27
      
    }
]



const Gallery = () => {
    const [model,setModel]=useState(false)
    const [tempSrc,setTempSrc]=useState()
    const modelHandler=(imgSrc)=>{
       setTempSrc(imgSrc)
       setModel(true)
    }
    return (
        <Fragment>
        <div className={model?"open model":"model"}>
         <img src={tempSrc} alt="screen readers"/>
         <RiCloseLine id="close" size="38"onClick={()=>setModel(false)}/>
        </div>
        <div className="gallery">
            {galleryData.map((item,index)=>{
            return <div className="pics" key={index} onClick={()=>modelHandler(item.imgSrc)}>
                 <img src={item.imgSrc} alt="screen readers"/>
                 <h4 className="gallery__title">{item.title}</h4>
                 <p className="gallery__text">{item.text}</p>
             </div>
            })}
        </div>
        </Fragment>
    )
    
}

export default Gallery