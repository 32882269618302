import React from 'react'
import './chat.css'
import whatsApp from '../assets/whatsapp-logo-png-2263.png'
function Chat() {
    return (
        <div className="position-center">
            <div className="whatsApp">
            <a href=" https://wa.me/77657388"><img  src={whatsApp} alt="app"/></a>
            </div>
           
        </div>
    )
}

export default Chat

