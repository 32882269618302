import React from 'react'
import {Parallax} from 'react-parallax'
import Zebra from '../assets/zebras-1883654_1280.jpg'
function ImageTwo() {
    return (
        <div>
            <Parallax  className="parallax" bgImage={Zebra} bgImageAlt="zebra" strength={800}>
      <div className="safari">
          <span className="safari-text">
       Our Travel and Tour services also covers various tourists destinations throughout
       the country.Feel Afrika,feel at home.
          </span>
      </div>
    </Parallax> 
        </div>
    )
}

export default ImageTwo
