import React from 'react'
import './massage.css'
import massage from '../assets/massage-therapy-1612308_1280.jpg'
import Wellness from '../assets/oil-4369051_1280.jpg'
import Spa from '../assets/essential-oil-1731518_640.jpg'
import spa1 from '../assets/massage-3607837_1280.jpg'
import Fade from 'react-reveal/Fade'
function Massage() {
    return (
        <div className="massage-all">
           <Fade>
            <div className="open-massage">
                <div className="small-one">
               
                    <div className="span-massage">
                    <img src={massage} alt="design"/>
                    <div className="text">
                        <h4>Deep Tissue</h4>
                        <span>60/90 MINS</span>
                        <br/>
                    <span>P400/P600</span>
                    </div>
                    </div>
                </div>
                <div className="small-one">
                
                    <div className="span-massage">
                    <img src={Wellness} alt="design"/>
                    <div className="text">
                        <h4>Hot Stone</h4>
                       
                        <span>60/90 MINS</span>
                        <br/>
                    <span className="cost">P400/P600</span>
                    </div>
                    </div>
                </div>
                <div className="small-one">
              
                    <div className="span-massage">
                    <img src={Spa} alt="massage"/>
                    <div className="text">
                        <h4>Swedish</h4>
                        <span>60/90 MINS</span>
                        <br/>
                    <span className="cost">P350/P450</span>
                    </div>
                    </div>
                </div>
                <div className="small-one">
              
                    <div className="span-massage">
                    <img src={spa1} alt="massage"/>
                    <div className="text">
                        <h4>Aromatherapy</h4>
                        <span>60/90 MINS</span>
                        <br/>
                    <span className="cost">P350/P400</span>
                    </div>
                    </div>
                </div>
            </div>
            </Fade>
        </div>
    )
}

export default Massage
