import React from 'react'
import Flash from 'react-reveal/Flash'
import './cover.css'
function Cover(props) {
    return (
        <div className="overlay">
          <Flash> <img src={props.photo} alt="cuisines"/> </Flash>
          
        </div>
    )
}

export default Cover
