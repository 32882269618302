import React from 'react'
import {Parallax} from 'react-parallax'
import Safari from '../assets/elephants-4275741_1280.jpg'
function ImageOne() {
    return (
        <div>
            <Parallax  className="parallax" bgImage={Safari} bgImageAlt="safari" strength={800}>
      <div className="safari">
          <span className="safari-text">
             African Home Travel and Tour services offer our customers a unique opportunity
             to visit and appreciate our city Gaborone.
          </span>
      </div>
    </Parallax> 
        </div>
    )
}

export default ImageOne
