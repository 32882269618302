import React from 'react'

function Map() {
    return (
        <div className="map">
            <h1 style={{padding:"10px",color:"burlywood"}}>Find us here:</h1>
            <div className="find-use">
                       

           <iframe width="600" height="500" style={{width:"100%",height:"600px"}} id="gmap_canvas" src="https://maps.google.com/maps?q=African%20Home%20Hotel,Botswana&t=&z=13&ie=UTF8&iwloc=&output=embed" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0"></iframe>   





            </div>
        </div>
    )
}

export default Map
