import React from 'react'
import MagicOne from './MagicOne'
import MagicTwo from './MagicTwo'
import MagicThree from './MagicThree'
function Rounded() {
    return (
        <div className="rounded">
            <h1 style={{color:"#b48338",padding:"10px"}}>For stress relief,anxiety and depression.</h1>
            <MagicOne/>
            <MagicTwo/>
            <MagicThree/>
        </div>
    )
}

export default Rounded
