import React from 'react'
import {Link} from 'react-router-dom'

import Bounce from 'react-reveal/Bounce'
import Zoom from 'react-reveal/Zoom'
import './Delivery.css'
function Delivery() {
    return (
        <div className="product-delivery">
            <div className="top-class">
           
                   
           <Bounce top>
                <div className="delivery-aside">
                    <h1 style={{color:"burlywood",textAlign:"center"}}>Beauty  Spa</h1>
                    <p>Beauty Spa winter special.Full body massage 
                        for 1 hour at P250.00 only,every monday,wednesday and friday.
                    </p>
                    <Link to="/beauty"><button className="delivery-btn">View More</button></Link>
                </div>
                </Bounce>
                           
           <Bounce top>
                <div className="delivery-aside">
                    <h1 style={{color:"burlywood",textAlign:"center"}}>Kids Mania</h1>
                    <p>Get a burger,fries and juice plus free swimming for only P70/child.
                    </p>
              
                </div>
                </Bounce>
                           
           <Bounce top>
                <div className="delivery-aside">
                    <h1 style={{color:"burlywood",textAlign:"center"}}>Weekend Accommodation</h1>
                    <p>Executive Room for only P750.00,Standard Room only for P550.00.Dont 
                        miss out every Friday and Saturday.
                    </p>
                   
                </div>
                </Bounce>
            </div>
        </div>
    )
}

export default Delivery
