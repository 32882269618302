import React from 'react'
import Cover from './Cover'
import Food from './Food'

import Menus from './Menus'
import FoodCircle from './FoodCircle'
import image from '../assets/kids.JPG'
const CuisineScreen = () => {
    return (
        <div>
            <Cover photo={image}/>
            <Food/>
          <Menus/>
       
             <FoodCircle/>
        </div>
    )
}

export default CuisineScreen
