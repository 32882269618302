import React from 'react'
import './moving.css'
import art from '../assets/move1.JPG'
import art1 from '../assets/move2.JPG'
import art2 from '../assets/move3.JPG'
import art3 from '../assets/move4.JPG'
import art4 from '../assets/move5.JPG'
import art5 from '../assets/resizeTwo.JPG'
function Moving() {
    return (
        <div className="my__sport__autoplay">
            <div className="my__sport__sliding__images">
               <div className="my__sport__image">
                   <img src={art} alt="partners"/>
                
               </div>
               <div className="my__sport__image">
                   <img src={art1} alt="partners"/>
               </div>
               <div className="my__sport__image">
                   <img src={art2} alt="partners"/>
               </div>
               <div className="my__sport__image">
                   <img src={art3} alt="partners"/>
               </div>
               <div className="my__sport__image">
                   <img src={art4} alt="partners"/>
               </div>
               <div className="my__sport__image">
                   <img src={art5} alt="partners"/>
               </div>
            </div>
        </div>
    )
}

export default Moving
