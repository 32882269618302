import React from 'react'
import Cover from './Cover'

import RoomOne from '../Components/RoomOne'

import Room from '../assets/niceroom2.jpg'

import RoomDetail from '../Components/RoomDetail'



import ImageSlider from './ImageSlider'

import ImageSliderB from './ImageSliderB'

function RoomsScreen() {
    return (
        <div>
            
             <Cover photo={Room}/>
         
            <RoomOne header="EXECUTIVE ROOM"
            title="King Sized Beds"
            text="Our exquisite rooms are equipped with a min bar fridge,DSTV,telephone line
            ,free unlimited WIifi,air conditioning,mini safe,mosquito nets,bath tub and shower
            .Instant geyser plus an electric kettle.
            "
            />
              <ImageSlider/>
             <RoomDetail
            text="Air conditioner"
            text1="Free TV"
            text2="Hot water"
            text3="Full breakfast"
            list="Spacious "
            list1="Natural light"
            list2="Safe and secure"
            detail="Luxurious and Elegant"
            price="P850/per night"
            />
           
            
               <RoomOne header="STANDARD ROOM"
            title="Queen Double Bed"
            text="Our 17 standard luxurious rooms are equipped with a min bar,fridge,DSTV,flat screen television
            ,telephone line,unlimited free Wifi,Air conditioning,mini safe,shower,instant geyser and kettle.

            "
            />
             <ImageSliderB/>
               <RoomDetail
            text="Air conditioner"
            text1="Free TV"
            text2="Hot water"
            text3="Full breakfast"
            list="Spacious room"
            list1="Economic"
            list2="Well ventilated"
           list3="Safe and Secure"
            detail="Simple with class"
            price="P650/per night"
            />
              
            
 
        </div>
    )
}

export default RoomsScreen
