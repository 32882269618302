import React from 'react'
import ImageOne from '../Components/ImageOne'
import ImageTwo from '../Components/ImageTwo'
import ImageThree from '../Components/ImageThree'
function Parallax() {
    return (
        <div>
            <ImageOne/>
            <ImageTwo/>
            <ImageThree/>
        </div>
    )
}

export default Parallax
