import React from 'react'
 import {Parallax} from 'react-parallax'
 import imagePara from '../assets/conf.jpg'
function ParallaxTwo() {
    return (
        <div>
           <Parallax  className="parallax-one" bgImage={imagePara} bgImageAlt="safari" strength={800}>
      <div className="safari-one">
          <span className="safari-text-one">
             Our conference rooms are built for high mass events not just corporate meetings or commercial.
             Social gatherings like weddings,bidding and conventions of different social groups.
          </span>
      </div>
    </Parallax>  
        </div>
    )
}

export default ParallaxTwo
