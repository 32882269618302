import React from 'react'
import Deer from '../assets/potters-1985519_1920.jpg'
import Butterfly from '../assets/EDIT 2.JPG'
import Pot from '../assets/POT EDITED.JPG'
import Zoom from 'react-reveal/Zoom'
import './shop.css'
function Shop() {
    return (
        <div className="shop_cover_content">
           <div className="div_plus_image">
            <div className="overlay_shop">
                <div className="div_word">
                    <h1>Experience your <span className="span">creative</span> sense.</h1>
                    <p className="div_content">
                       Our craft shop is the ultimate sanctuary  where you get a piece
                       of skill,imagination and artistic exploration.We exhibit stone art
                       ,wooden art and other products that are hand crafted by
                        talented artists both locally and regionally.

                    </p>
                    <button className="shop_button"><a href="http://africanhomereservations@gmail.com">Enquiries</a></button>
                </div>
                <div className="image_shop_one">
                   <Zoom> <img src={Butterfly} alt="flower"/></Zoom>
                    <button className="button_image">Arts & Craft</button>
                </div >
                <div className="image_shop_two">
                   <Zoom> <img src={Pot} alt="flower"/></Zoom>
                    <button className="button_image_two">African Traditional Pot</button>
                </div >
            </div>

        
          
           
               </div> 
        </div>
    )
}

export default Shop
