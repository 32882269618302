import React from 'react'
import {Link} from 'react-router-dom'
import Home from '../assets/The curve.jpeg'
import Zoom from 'react-reveal/Zoom'
import Swing from 'react-reveal/Swing'
import './open.css'
function Open() {
    return (
    <div className="open-all">
        <Swing><Link to="/about"><img src={Home} alt="parallox"/></Link></Swing>
        
        <div className="four">
            <Zoom>
             <aside className="para">
               
                 <h1>KNOW THE PLACE BETTER</h1>
                 <h3>A natural Habitat</h3>
                 <p>The heart of African tradition infused in a living experience.
                     The hub for Accommodation,Spar treatments,Conferencing
                     ,both local and international scrumptious cuisines.
                     A centralized hotel in proximity to a variety of amenities.
                 </p>
             </aside>
             </Zoom>
             <Zoom>
             <aside className="para">
               
             <h1>BEAUTY WITH A DIFFERENCE </h1>
                 <p>Designed and built to give you a different perception of Africanacity merged with modernization.
                 </p>
             </aside>
             </Zoom>
        </div>
    </div>
    )
}

export default Open
