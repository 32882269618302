import React from 'react'
import {Routes,Route} from 'react-router-dom'
import HomeScreen from '../../Components/HomeScreen'
import AboutScreen from '../../Components/AboutScreen'
import CuisineScreen from '../../Components/CuisineScreen'
import BeautyScreen from '../../Components/BeautyScreen'
import RoomsScreen from '../../Components/RoomsScreen'
import ShopScreen from '../../Components/ShopScreen'
import Gallery from '../../Components/Gallery'
import PhaseFourRoom from '../../Components/PhaseFourRoom'
function Screen() {
    return (
        <Routes>
          <Route path="/" element={<HomeScreen/>}/>  
          <Route path="/about" element={<AboutScreen/>}/>
          <Route path="/cuisines" element={<CuisineScreen/>}/>
          <Route path="/beauty" element={<BeautyScreen/>}/>
          <Route path="/rooms" element={<RoomsScreen/>}/>
          <Route path="/gallery" element={<Gallery/>}/>
          <Route path="/shop" element={<ShopScreen/>}/>
          <Route path="/phase4" element={<PhaseFourRoom/>}/>
        </Routes>
    )
}

export default Screen
