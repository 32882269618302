import React from 'react'
 import {Parallax} from 'react-parallax'
 import imagePara from '../assets/conferencenice.jpg'
function ParallaxOne() {
    return (
        <div>
           <Parallax  className="parallax-one" bgImage={imagePara} bgImageAlt="safari" strength={800}>
      <div className="safari-one">
          <span className="safari-text-one">
            Excellent conference rooms for different events,modern and mounted with teleconferencing
            features for presentations,pitch and can also host big music events.
          </span>
      </div>
    </Parallax>  
        </div>
    )
}

export default ParallaxOne
