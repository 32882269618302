import React from 'react'
import LightSpeed from 'react-reveal/LightSpeed'
import Flip from 'react-reveal/Flip'
import './RoomDetail.css'
function RoomDetail({text,text1,text2,text3,list,list1,list2,list3,price,detail}) {
    return (
        <div className="room-detail">
            <div className="details">
                <LightSpeed>
                <div className="list-quality">
                    <h3>A real home with comfort</h3>
                   <div className="quality">
                       <p>{text}</p>
                       <p>{text1}</p>
                       <p>{text2}</p>
                      
                   </div>
                </div>
                </LightSpeed>
                <div className="list-quality">
                    <Flip>
                   <div className="quality">
                   <h3>Experience a stay with adventure</h3>
                       <p>{list}</p>
                       <p>{list1}</p>
                       <p>{list2}</p>
                      
                   </div>
                   </Flip>
                </div>
                <div className="list-quality">
                <h3>Have a cost effective stay </h3>
                   <div className="price">
                       <p>{detail}</p>
                   
                       <p style={{color:"burlywood",fontSize:"45px",border:"3px solid white"}}>{price}</p>
                   </div>
                </div>
            </div>
        </div>
    )
}

export default RoomDetail
