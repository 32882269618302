import React from 'react'
import Navigation from './Components/Navigation'
import Screen from './Components/Screens/Screen'
import Footer from './Components/Footer'


function App() {
  
  
   
  return (
    <div className="no-overflow">
     <Navigation/>
   

   <Screen/>
  

     
     
     <Footer/>
 </div>
  );
}

export default App;
