import React from 'react'
 import {Parallax} from 'react-parallax'
 import imagePara from '../assets/wellness-589772_1280.jpg'
function MagicOne() {
    return (
        <div>
           <Parallax  className="massage-one" bgImage={imagePara} bgImageAlt="safari" strength={800}>
      <div className="massage-one-unit">
        
          <span className="massage-text-one">
         Our stunning beauty spa offers exclusive massage therapy of different kinds,targeting 
         specific parts for different outcomes.
          </span>
         
      </div>
    </Parallax>  
        </div>
    )
}

export default MagicOne;
