import React,{useState,useEffect} from "react";
import "./Navigation.css";
import Logo from "../assets/LOGO 2021.png";
import { NavLink, Link} from "react-router-dom";
import {RiCloseLine,RiMenu3Line} from 'react-icons/ri'
import {FaFacebookF,FaInstagram} from 'react-icons/fa'
import Bounce from 'react-reveal/Bounce'
const Navigation = () => {
  const [toggleMenu,setToggleMenu]=useState()
  const [show,handleShow]=useState(false)
  useEffect(()=>{
      window.addEventListener('scroll',()=>{
        if(window.scrollY){
          handleShow(true)
        }else handleShow(false)
      });
      return()=>{
        window.removeEventListener('scroll')
      }
  },[])
  return (
 
    <div className={`gpt3__navbar ${show && "nav__brown"}`}>
    <div className="gpt3__navbar-links">
      <div className="gpt3__navbar-links_logo">
       <Link to="./"> <img src={Logo} alt="navbar"/></Link>
      </div>
      <div className="gpt3__navbar-links_container">
        <p><NavLink to="/">Home</NavLink></p>
        <p><NavLink to="/beauty">Beauty Spa</NavLink></p>
        <p><NavLink to="/cuisines">Restaurant</NavLink></p>
        <p><NavLink to="/rooms">Rooms</NavLink></p>
        <p><NavLink to="/phase4">Phase4</NavLink></p>
        <p><NavLink to="/shop">Shop</NavLink></p>
        <p><NavLink to="/gallery">Gallery</NavLink></p>
        <p><NavLink to="/about">About Us</NavLink></p>
      </div>
    </div>
    <div className="gpt3__navbar-sign">
     
     <a href="https://www.facebook.com/originalafricanhome">  <FaFacebookF color="blue" size={33}/> </a>
   
      <a href="https://www.instagram.com/african_home_accommodation/"><FaInstagram color="orangered" size={33}/></a>
     
    </div>
    <div className="gpt3__navbar-menu">
      {toggleMenu
        ? <RiCloseLine color="burlywood" size={35} onClick={() => setToggleMenu(false)} />
        : <RiMenu3Line color="burlywood" size={33} onClick={() => setToggleMenu(true)} />}
      {toggleMenu && (
         <Bounce top>
      <div className="gpt3__navbar-menu_container scale-up-center">
       
        <div className="gpt3__navbar-menu_container-links">
        <p><NavLink to="/">Home</NavLink></p>
        <p><NavLink to="/beauty">Beauty Spa</NavLink></p>
        <p><NavLink to="/cuisines">Restaurant</NavLink></p>
        <p><NavLink to="/rooms">Rooms</NavLink></p>
        <p><NavLink to="/phase4">Phase4</NavLink></p>
        <p><NavLink to="/shop">Shop</NavLink></p>
        <p><NavLink to="/gallery">Gallery</NavLink></p>
        <p><NavLink to="/about">About Us</NavLink></p>
        </div>
      
        <div className="gpt3__navbar-menu_container-links-sign">
        
        
        </div>
       
      </div>
      </Bounce>
      )}
    </div>
  </div>


      
     
   
  );
};

export default Navigation;
