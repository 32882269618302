import React from 'react'
import Cover from '../Components/Cover'
import ImageSliderC from '../Components/ImageSliderC'
import ImageSliderD from '../Components/ImageSliderD'
import RoomDetail from '../Components/RoomDetail'
import RoomOne from '../Components/RoomOne'
import Phase4 from '../assets/IMG_3746 EDITED.JPG'
function PhaseFourRoom() {
    return (
        <div>
            <Cover photo={Phase4} />

            
              <RoomOne header="EXECUTIVE ROOM"
            title="King Sized Beds"
            text="Our luxurious executive  rooms are spacious and come equipped with a mini bar fridge,DSTV,
            ,free unlimited Wifi,air conditioning. Instant geyser plus an electric kettle.
           
            "
            />
               <ImageSliderC/>
              <RoomDetail
            text="Air conditioner"
            text1="Free TV"
            text2="Hot water"
            text3="Full breakfast"
            list="Spacious "
            list1="Natural light"
            list2="Safe and secure"
            detail="Luxurious and Elegant"
            price="P400/per night"
            />
                <RoomOne header="STANDARD ROOM"
            title="King Sized Beds"
            text="Our standard rooms are equipped with a min bar fridge,DSTV,
            ,free unlimited WIifi,air conditioning .Instant geyser plus an electric kettle.
           
            "
            />
              <ImageSliderD/>
          
              <RoomDetail
            text="Air conditioner"
            text1="Free TV"
            text2="Hot water"
            text3="Full breakfast"
            list="Spacious "
            list1="Natural light"
            list2="Safe and secure"
            detail="Luxurious and Elegant"
            price="P350/per night"
            />
        </div>
    )
}

export default PhaseFourRoom
