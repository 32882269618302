import React,{useState} from 'react'
import Menu1 from '../assets/eats.jpg'
import Menu2 from '../assets/animals.jpeg'
import Menu3 from '../assets/hotfood.jpg'

import Lunch from '../assets/pasta-6263653_1280.jpg'

import Desserts from '../assets/strawberry-dessert-2191973_1280.jpg'
import Meal from '../assets/ukrainian-dill-potatoes-2652561_1280.jpg'
import Bread from '../assets/bread-18987_1280.jpg'
import Sugar from '../assets/dessert-1786311_1280.jpg'
import Reusable from './Reusable'
import './menus.css'
function Menus() {
    const [showMenu,setShowMenu]=useState(false)
    const[meal,setMeal]=useState(false)
    const [mealOne,setMealOne]=useState(false)
    const [mealTwo,setMealTwo]=useState(false)    
    const mealOneHandle=()=>{
        if(mealOne==true){
            setMealOne(false)
        }else{
            setMealOne(true)
        }
    }
    const mealTwoHandle=()=>{
        if(mealTwo==false){
            setMealTwo(true)
        }else{
            setMealTwo(false)
        }
    }
    const mealHandle=()=>{
        if(meal==false){
            setMeal(true)
        }else{
            setMeal(false)
        }
    }
    const clickHandle=()=>{
        if(showMenu==false){
            setShowMenu(true)
        }else{
            setShowMenu(false)
        }
    }
    return (
        <div className="Menu">
            <h1 style={{color:"burlywood"}}>OUR EXCLUSIVE MENUS</h1>
            <div className="menu_whole">
                <div className="food-card-1">
                <img src={Menu1} alt="menu"/>
            <div className="main_Menu">
               
                <p>Treat yourself to mouth watering and affordable lunch from our different
                    menu options.
                </p>

              {meal?<Reusable
              imagery={Lunch}
               heading="Lunch"
               description="Our Buffet dinner or lunch.Dijo tsa Setswana and seswaa."
           
           
              
              />:null}
                <button onClick={mealHandle}>Lunch</button
                   >
                 
             
               
            </div>
                </div>
                <div className="food-card-2">
                <img src={Menu3} alt="menu"/>
            <div className="main_Menu">
               
                <p>Whatever your diet,we have a variety of options to meet your nutritional needs.
                </p>

              {mealOne?<Reusable
            
            imagery={Bread}
            heading="Breakfast"
            description="A full English breakfast,African sunshine.
           "
          
        
              
              />:null}
                <button onClick={mealOneHandle}>Breakfast</button>
               
            </div>
                </div>
             
                <div className="food-card-3">
                <img src={Desserts} alt="menu"/>
            <div className="main_Menu">
               
                <p> An ample array of gorgeous desserts to choose from. Succulent treats to complete your meal.
                </p>

              {showMenu?<Reusable
             
             imagery={Sugar}
             heading="Desserts"
             description="Milkshakes,muffins and nice pudding flavors.
            "
         
              
              />:null}
                <button onClick={clickHandle}>Desserts</button>
               
            </div>
                </div>
                <div className="food-card-4">
                <img src={Menu2} alt="menu"/>
            <div className="main_Menu">
               
                <p>Enjoy your dinner with an African Home feel.Ala-Carte,Buffet
                    and platter menus.
                </p>

              {mealTwo?<Reusable
             
             imagery={Meal}
             heading="Dinner"
             description="Phaleche,rice,pasta and more credible dinners available on order.
            "
         
              
              />:null}
                <button onClick={mealTwoHandle}>Dinner</button>
               
            </div>
                </div>
            </div>
            
        </div>
    )
}

export default Menus;
