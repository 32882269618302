import React from 'react'
import AnimateBG from './AnimateBG'
import CTA from './CTA'
import Mission from './Mission'
import Open from './Open'
import Services from './Services'
import Parallax from './Parallax'
import Delivery from './Delivery'
import Chat from './Chat'
import Map from './Map'

function HomeScreen() {
 
    return (
        <div className="home__page">
 
        <AnimateBG/>
   
        <Mission/>
   
        <CTA/>
      
        <Services/>
      
     <Parallax/>
        <Chat/>
       
      
       <Open/>
    

       <Delivery/>
       
     <Map/>
        </div>
    )
}

export default HomeScreen
