import React from 'react'
import './favorites.css'
import buy from '../Components/buy'
function Favorites() {
    return (
        <div className="scroll_mouse_container">
            <h4 className="header_scroll">Your favorites</h4>
            <div className="scroll_slide_aside">
               {buy.map((item)=>(
                <div key={item.id} className="card_for_scroll">
                   <div className="product">{item.product}</div>
                   <img src={item.image} alt="craft images"/>
                   <div className="description">{item.description}</div>
                  
                </div>
               ))}
            </div>
        </div>
    )
}

export default Favorites
