import React from 'react'
import Shop from '../Components/Shop'
import Moving from '../Components/Moving'
import Scroll from '../Components/Scroll'
import Favorites from '../Components/Favorites'
function ShopScreen() {
    return (
        <div>
            <Shop/>
            <Scroll/>
            <Favorites/>
            <Moving/>
          
        </div>
    )
}

export default ShopScreen
