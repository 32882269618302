import React from 'react'
import Rice from '../assets/fries.jpg'
import Pasta from '../assets/pasta-6263653_1280.jpg'
import Local from '../assets/localfood.jpg'
import Bounce from 'react-reveal/Bounce'
import Fade from 'react-reveal/Fade'
import Flash from 'react-reveal/Flash'
import './food.css'
function Food() {
    return (
        <div className="main-container ">
            <h1 style={{color:"burlywood"}}>Get all your favorite food.</h1>
            <div className="food-cover">
               <div className="food-one">
                   <h3>International tastes</h3>
                  <Flash> <img src={Rice} alt="exotic"/></Flash>
                   <h4>Come explore our menu</h4>
                   <p>Our restaurant is diverse and all inclusive.
                       We offer delicious treats from all over the world
                       .Our kitchen staff have been trained to deliver timely
                        exquisite dishes to the customers satiety.</p>
               </div>
               <div className="food-two">
               <h3>Local flavours</h3>
                  <Flash> <img src={Local} alt="local"/></Flash>
                   <h4>Local dishes available</h4>
                   <p>We offer local favorites like Samp,Bogobe,Lerotse,Dinawa,Koko ya setswana and many more.</p>
               </div>
            </div>
            
        </div>
    )
}

export default Food
