import React from 'react'
 import {Parallax} from 'react-parallax'
 import imagePara from '../assets/conferencebig.jpg'
function ParallaxThree() {
    return (
        <div>
           <Parallax  className="parallax-one" bgImage={imagePara} bgImageAlt="safari" strength={800}>
      <div className="safari-one">
          <span className="safari-text-one">
             We have 3 big massive conference rooms and you can book today and tell us when 
             you want your event to be hosted.
          </span>
      </div>
    </Parallax>  
        </div>
    )
}

export default ParallaxThree
