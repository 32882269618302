import React from 'react'
 import {Parallax} from 'react-parallax'
 import imagePara from '../assets/wellness-589773_1280.jpg'
function MagicTwo() {
    return (
        <div>
           <Parallax  className="massage-one" bgImage={imagePara} bgImageAlt="safari" strength={800}>
      <div className="massage-one-unit">
          
          <span className="massage-text-one">
            Look out for our daily promotions for different treatments.You can keep yourself up to date by
            checking our website,facebook,instagram or by contacting us.You are invited to
             come and enjoy at the hands of our spectacular experts.
          </span>
          
      </div>
    </Parallax>  
        </div>
    )
}

export default MagicTwo;