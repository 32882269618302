import React from 'react'

import Quality from './Qualities'
import Cards from './Cards'

import Cover from './Cover'
import African from '../assets/goodnight.jpeg'


import MainParallax from './MainParallax'
function AboutScreen() {
    return (
        <div>
            <Cover photo={African}/>
    
          <Cards/>
         <MainParallax/>
          <Quality/>
        </div>
    )
}

export default AboutScreen
