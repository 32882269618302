import React from 'react'
import './Services.css'
import {FaStarHalfAlt,FaStar} from 'react-icons/fa'
import {Link} from 'react-router-dom'
import Room from '../assets/roomafricaA.JPG'
import Foodie from '../assets/cuisines.jpg'
import Bounce from 'react-reveal/Bounce'
import conference from '../assets/conference2.jpg'
function Services() {
    return (
        <div className="services-div">
            <h3 style={{color:"#231709"}}>Popular services:</h3>
            <div className="services-content">
                <div className="accommodation">
                  <Bounce right>   <img src={Room} alt="ROOM"/></Bounce>
                     <h4>Accommodation</h4>
                     <FaStar color="gold"/> <FaStar color="gold"/> 
                     <FaStar color="gold"/>  <FaStar color="gold"/>   <FaStar color="gold"/>
                   
                     <h5>Five star rated on booking.com
                     </h5>
                  
                    <Link to="/rooms"> <button>View Rooms</button></Link>
                </div>
                <Bounce top> 
                <div className="conference">
                    <Link to="/about"><img src={conference} alt="conference"/></Link>
                     <h4>Conference Centre</h4>
                     
                     <p >We have multiple conference rooms with telepresence and projectors, with our largest
                         accommodating up to 400 people,it is an ideal venue for all your corporate 
                         requirements such as conferences,seminars,training workshops and social events.
                     </p>
                </div>
                   </Bounce>
                   <Bounce left>
                <div className="eating-place">
                     <img src={Foodie} alt="foodie"/>
                    <h4>Visit Restaurant</h4>
                  <p>Come and try out  mouth watering cuisines prepared by our savvy chefs.</p>
                     <Link to="/cuisines"><button>Restaurant</button></Link>
                </div>
                </Bounce>
            </div>
        </div>
    )
}

export default Services
