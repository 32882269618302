import React from 'react'
import Zoom from 'react-reveal/Zoom'
import './Reusable.css'
function Reusable({heading,description,imagery}) {
    return (
        <div className="card-food-app">
            <Zoom>
            <div className="card-hover-food">
                <h2>{heading}</h2>
            <div className="picture ">   
            <img src={imagery} alt="menu" style={{width:"200px",height:"200px",borderRadius:"50%",marginLeft:"30px",border:"green"}}/>
            </div>
               <div className="price-reuse">
 
               </div>
                
                  <div className="description">
                  <p>{description}</p>
           
                  </div>
                 
            </div>
            </Zoom>
        </div>
    )
}

export default Reusable
