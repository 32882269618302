import React,{useState} from 'react'
import './imageSliderC.css'

import Slider1 from '../assets/DINE.JPG'
import Slider from '../assets/IMG_3761 EDITED.JPG'
import Slider3 from '../assets/bath1.JPG'


function ImageSliderC() {
  
  const imgs=[
    {id:0,value:Slider},
    {id:1,value:Slider1},
    {id:2,value:Slider3},
    
  ]
  const [wordData,setWordData]=useState(imgs[0])
  const handleClick=(index)=>{
    console.log(index)
    const wordSlider=imgs[index];
    setWordData(wordSlider)
  }
  return (
    <div className="main-top">
      <img src={wordData.value} width="300" height="300" alt="slider" /> 
      <div className='flex_row'>
        {imgs.map((data,i)=>
        <div className="thumbnail" key={i} >
          <img className={wordData.id==i?"clicked":""} src={data.value} onClick={()=>handleClick(i)} height="70" width="100" alt="slider" />
        </div>
        )}
      </div>
    </div>
  );
}

export default ImageSliderC;
