import React from 'react'
import {Parallax} from 'react-parallax'
import Giraffe from '../assets/giraffe-4312090_1280.jpg'
function ImageThree() {
    return (
        <div>
            <Parallax  className="parallax" bgImage={Giraffe} bgImageAlt="giraffe" strength={800}>
      <div className="safari">
          <span className="safari-text">
             Our guest shuttle services are a call away to the convenience of our arriving and departing
             customers to/from the various destinations in and around Gaborone including S.S.K international
             airport and various Bus Terminal Services
          </span>
      </div>
    </Parallax> 
        </div>
    )
}

export default ImageThree
