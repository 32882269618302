import React from 'react'
 import {Parallax} from 'react-parallax'
 import imagePara from '../assets/wellness-1021131_1920.jpg'
function MagicThree() {
    return (
        <div>
           <Parallax  className="massage-one" bgImage={imagePara} bgImageAlt="safari" strength={800}>
      <div className="massage-one-unit">
         
          <span className="massage-text-one">
        We offer a variety of massage treatments,that include Hot stone
        ,Aromatherapy,Sweedish,Indian head massage and many more.
                    
          </span>
         
      </div>
    </Parallax>  
        </div>
    )
}

export default MagicThree;