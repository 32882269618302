import React from 'react'
import Cover from './Cover'
import Social from '../assets/spa-4481583_1280.jpg'
import Spar from './Spar'
import Rounded from './Rounded'
import Massage from './Massage'

function BeautyScreen() {
    return (
        <div>
            <Cover photo={Social}/>
         
            <Spar/>
        
         <Rounded/>
            <Massage/>
        </div>
    )
}

export default BeautyScreen
