import React,{useState} from 'react'
import './AnimateBG.css'
import Bounce from 'react-reveal/Bounce'

function AnimateBG() {
    const [enterName,setEnterName]=useState()
    const [email,setEmail]=useState()
    const [service,setService]=useState()
    const [success,setSuccess]=useState(false)
   const [serviceFailure,setServiceFailure]=useState(false)
    const [errorName,setErrorName]=useState()
    const [errorEmail,setErrorEmail]=useState()
    const nameHandle=(event)=>{
        setSuccess("")
        setErrorName("")
       setEnterName(event.target.value)
    }
    
    const emailHandle=(event)=>{
        setSuccess("")
        setErrorEmail("")
        setEmail(event.target.value)
   }
    const serviceHandle=(event)=>{
        setSuccess("")
        setServiceFailure("")
        setService(event.target.value)
    }
    const submitHandle=(event)=>{
        event.preventDefault()
        if(email!==''){
            // do something

          
        }else{
            setErrorEmail('Email can not be empty')
        }
        if(enterName!==''){
            // do something here
            
       }else{
            setErrorName('Name can not be empty!')
        }
        if(service!==''){
            // do something here
         
        }else{
            setServiceFailure('Please enter a bookable service')
        }
        setEnterName('')
       setEmail('')
       setService('')
    
    }
   
    return (
        <div className="container">
        
            <div className="overlay-cover">
                {success&&<div className="success_msg">{success}</div>}
                <h1 style={{color:"burlywood",padding:"8px",textAlign:"center"}}>BOOKING DETAILS</h1>
              
                <Bounce>
                    
             <form  onSubmit={submitHandle} >

              
                    <div className="what-you-looking-for">
                        <label>Name</label>
                        <input type="text" 
                       value={enterName}
                       onChange={nameHandle}
                            placeholder="Enter full name."
                        name="name" id="ins"/>
                       {errorName&& <div className="email_error">{errorName}</div>}
                    </div>
                    <div className="check-ins">
                        <label>Service</label>
                        <input type="text" 
                      value={service}
                     placeholder="Enter bookable service."
                      onChange={serviceHandle}
                        name="service" id="date-in"/>
                       {serviceFailure&& <div className="email_error">{serviceFailure}</div>}
                    </div>
           
                <div className="check-outs">
                        <label>Email</label>
                        <input type="email"
                        placeholder="Send us a valid email."
                        value={email}
                        onChange={emailHandle}
                   
                        name="email" id="date-out"/>
                        {errorEmail&& <div className="email_error">{errorEmail}</div>}
                    
                    </div>
                    <div className="cta-button">

                    <button className="cta" type="submit">Submit</button>
                   
                    </div>
                </form>
                </Bounce>
            </div>
        </div>
    )
}

export default AnimateBG