import React from 'react'
import {IconContext} from 'react-icons'
import {MdAdsClick,MdRadar,MdModeStandby,MdLeaderboard} from 'react-icons/md'
import Zoom from 'react-reveal/Zoom'
import './mission.css'
function Mission() {
    return (
        <div className="mission">
              <Zoom>
            <div className="flex-this">
            <div className="mission-cover">
              
               <div className="mission-icon">
                   <IconContext.Provider value={{color:"#371D10",className:"mission-icon",size:"100"}}>
                   <MdAdsClick size="100"     />
                   </IconContext.Provider>
               </div>
               <h2>Mission</h2>
               <p>Our mission is to provide affordable, unique and comfortable Accommodation, Conferencing, Catering, Massage Spa and Travel & Tours.
               </p>
            </div>
            <div className="about-cover">
              
              <div className="about-icon">
                  <IconContext.Provider value={{color:"#371D10",className:"about-icon",size:"100"}}>
                  <MdRadar size="100"     />
                  </IconContext.Provider>
              </div>
              <h2>About Us</h2>
              <p>We have been in fully operational since 2008. Through the years we been providing ultimate satisfactory services in the hospitality Industry.

              </p>
           </div>
                <div className="goals-cover">
            <div className="goals-icon">
                <IconContext.Provider value={{color:"#371D10",className:"goals-icon",size:"100",backgroundColor:"#deb887"}}>
                   <MdLeaderboard />
                   </IconContext.Provider>
               </div>
               <h2>Consultancy</h2>
               <p>We offer consultancy services for
                    different occasions and companies both local and international.
               </p>
             
                </div>
             
                </div>
                </Zoom>
        </div>
    )
}

export default Mission
