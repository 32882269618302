import React from 'react'
import ParallaxOne from './ParallaxOne'
import ParallaxTwo from './ParallaxTwo'
import ParallaxThree from './ParallaxThree'
function MainParallax() {
    return (
        <div>
            <ParallaxOne/>
            <ParallaxTwo/>
            <ParallaxThree/>
        </div>
    )
}

export default MainParallax
