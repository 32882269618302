import React from 'react'
import Zoom from 'react-reveal/Zoom'
import './RoomOne.css'
function RoomOne({header,title,text}) {
    return (
        <div className="all-room">
            <Zoom>
            <div className="main-room">
                <h1>{header}</h1>
                <h3>{title}</h3>
                <p>{text}</p>
            </div>
            </Zoom>
        </div>
    )
}

export default RoomOne
