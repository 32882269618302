import React from 'react'
import image1 from '../assets/restaurant1.jpg'
import image2 from '../assets/pasta-6263653_1280.jpg'
import Bounce from 'react-reveal/Bounce'
import Flip from 'react-reveal/Flip'
import image3 from '../assets/massagefb3.jpeg'
import './FoodCircle.css'
function FoodCircle() {
    return (
        <div>
                <h1 style={{color:"burlywood",padding:"10px"}}>A few words about us:</h1>
        <div className="card">
        <Bounce>
           <aside className="detail">
           <img src={image1} alt="detail"  />
            
               <h3 style={{color:"burlywood"}}>Food for a unique people</h3>
               <p style={{color:"black"}}>Our kitchen of fun loving staff,
               prepares succulent food tailored to nourish not only your taste buds but also
               your sense of belonging to a welcoming home you wont want to leave.
               </p>
             
              
              
              
           </aside>
           </Bounce>
           <Flip>
          <aside className="double-images">
              <img src={image2} alt="view"/>
              <img src={image3} alt="viewer"/>
              <h3 style={{color:"burlywood"}}>Deliveries</h3>
              <p style={{color:"black"}}>
                      Our hotel offers delivery services for outside catering.
                      Place your order for delicious cuisines served at your convenience.
               </p>
          </aside>
          </Flip>
        </div> 
        </div>
    )
}

export default FoodCircle
