import React from 'react'

import './cards.css'
import image1 from '../assets/bar.jpg'
import image2 from '../assets/sleepOver.jpeg'
import image3 from '../assets/about.jpg'
import Bounce from 'react-reveal/Bounce'
import Zoom from 'react-reveal/Zoom'
function Cards() {
    return (
        <>
               <h1 className="h1">A few words about us:</h1>
        <div className="card"      >
        <Bounce top>
           <aside className="detail">
           <img src={image2} alt="detail"/>
            
               <h3>Our Background</h3>
               <p>Established in October 2006 by a local owner in the capital of Botswana.
                   An indigenous owned company established in 2006 in Gaborone the capital
                   of Botswana.
               </p>
             
              
              
              
           </aside>
           </Bounce>
           <Zoom>
          <aside className="double-images">
              <img src={image1} alt="about"/>
              <img src={image3} alt="about-us"/>
              <h3>Ready For You</h3>
              <p>The units in the hotel are equipped with a kettle. 
                   All rooms are fitted with air conditioning and a flat-screen TV, and certain units at African Home Hotel have a balcony.
Guests at the accommodation can enjoy a continental breakfast.
African Home Hotel offers a terrace.
Main Mall is 1.8 km from the hotel, while Government Enclave is 1.9 km away.

               </p>
          </aside>
          </Zoom>
        </div>
        </>
    )
}

export default Cards
