import React from 'react'
import './scroll.css'
import Slide1 from '../assets/potters-1985519_1920.jpg'
import Slide2 from '../assets/pottery-1283146_1280.jpg'
import Slide3 from '../assets/pot-576173_1280.png'
import stock  from '../Components/stock'
function Scroll() {
    return (
        <div className="scroll_mouse_container">
            <h4 className="header_scroll">In stock</h4>
            <div className="scroll_slide_aside">
               {stock.map((item)=>(
                <div key={item.id} className="card_for_scroll">
                   <div className="product">{item.product}</div>
                   <img src={item.image} alt="craft images"/>
                   <div className="description">{item.description}</div>
                 
                </div>
               ))}
            </div>
        </div>
    )
}

export default Scroll
