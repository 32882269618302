import React from 'react'
import {Link} from 'react-router-dom'
import Fb from '../assets/facebook-2429746_1280.png'
import Instagram from '../assets/instagram-1675670_640.png'
import Bounce from 'react-reveal'
import './Footer.css'
import Logo from '../assets/LOGO 2021.png'
const Footer = () => {
    return (
        
        <div className="african__footer">
            <hr/>
            <div className="african__footer__list">
                <div className="Logo">
                    <img src={Logo} alt="logo"/>
                </div>
                <div className="segment-1">
                    <h4>Business</h4>
                    <ul className="list-services">
                       <li><Link to="">Bar</Link></li>
                        <li><Link to="/rooms">Accommodations</Link></li>
                        <li><Link to="/cuisines">Catering</Link> </li>
                        <li><Link to="">Orders</Link></li>
                        <li><Link to="">Payment Methods</Link></li>
                    </ul>
                </div >
              
                <div className="segment-2">
                    <h4>For Public</h4>
                    <ul className="security-services">
                        <li>Liability Clauses</li>
                        <li>Non-Violent Conducts</li>
                        <li>Surveillance</li>
                        <li>Pool Policy</li>
                        <li>Contract to Stay</li>
                    </ul>
                </div >

                <div className="segment-3">
                    <h4>CSR</h4>
                    <ul className="list-services">
                        <li>Interns </li>
                        <li>Public Cleaning</li>
                        <li> Elderly and Orphan Care</li>
                        <li>Donations</li>
                        <li>Community Development</li>
                    </ul>
                </div >


                <div className="segment-1">
                    <h4>Feel Africa</h4>
                    <ul className="list-services">
                        <li>Ext11,Nyerere Drive</li>
                        <li>Plot 5345</li>
                        <li>Call +267 390 9440</li>
                        <li>PO BOX 40333</li>
                        <li>Gaborone</li>
                    </ul>
                </div >
                <div className="segment-social">
                   <Bounce top>
                   <div className="social-media">
                      <a href="https://www.facebook.com/originalafricanhome"><img src={Fb} alt="fb"/></a>
                      <a href="https://www.instagram.com/african_home_accommodation/"><img src={Instagram} alt="inst"/></a>
                   </div>
                   </Bounce>
               </div >
            </div>
        </div>
    )
}

export default Footer
