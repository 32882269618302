import React from 'react'
import bed from '../assets/people.jpeg'
import sofa from '../assets/IMG_3622.jpg'
import food from '../assets/AFRICAN HOME (1).jpg'

import './quality.css'
function Qualities() {
    return (
        <div className="all-quality">
        <h1 style={{color:"burlywood",padding:"2rem"}}>Our stand.One Community.One People.</h1>
        <div className="card-three">
            <div className="card-one">
                <img src={food} alt="offer"/>
               <h3>Diversity and Culture</h3>
               <p>We encompass the needs of every 
                   human without race,custom and culture.
                   Thats why we are an African Home,
                   we believe and embrace the spirit of oneness or "ubuntu".</p>
            </div>
            <div className="card-two">
                <img src={bed} alt="offer"/>
               <h3>Our Virtues and Values</h3>
               <p>Our moral code is to stand for a peaceful community that upholds
                   the rule of the land and acts based on love,kindness and family
                   orientation.</p>
            </div>
          
            <div className="card-3">
                <img src={sofa} alt="offer"/>
               <h3>Social Awareness</h3>
               <p>The African Home Hotel is fully
                    secured and under 24hrs surveillance.Any reckless conduct by anyone,the African Home will
                     legally exclude itself from any liability.</p>
            </div>
       
          
        </div>
        </div>
    )
}

export default Qualities
