import asset1 from '../assets/IMG_0630.JPG'
import asset2 from '../assets/trial copy.JPG'
import asset3 from '../assets/trial2 copy.JPG'
import asset4 from '../assets/trial3.JPG'
import asset5 from '../assets/trial4.JPG'

const stock=[
    {
        id:1,
        product:'Shop Gallery',
        image:asset1,
        description:'1'
       
    },
    {
        id:2,
        product:'Shop Gallery',
        image:asset2,
        description:'2'
        
    },
    {
        id:3,
        product:'Shop Gallery',
        image:asset3,
        description:'3'
        
    },
    {
        id:4,
        product:'Shop Gallery',
        image:asset4,
        description:'4'
        
    },
    {
        id:5,
        product:'Shop Gallery',
        image:asset5,
        description:'5'
        
    }

]
      

export default stock