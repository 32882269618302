import React, { useState } from 'react';
import './ImageSlider.css';
import Slider from '../assets/niceroom1.jpg'
import Slider1 from '../assets/sink2.jpg'
import slider3 from '../assets/sink.jpg'

function ImageSlider() {
  
  const imgs=[
    {id:0,value:Slider1},
    {id:1,value:slider3},
    {id:2,value:Slider},
    
  ]
  const [wordData,setWordData]=useState(imgs[0])
  const handleClick=(index)=>{
    console.log(index)
    const wordSlider=imgs[index];
    setWordData(wordSlider)
  }
  return (
    <div className="main-top">
      <img src={wordData.value} width="300" height="300" alt="slider" /> 
      <div className='flex_row'>
        {imgs.map((data,i)=>
        <div className="thumbnail" key={i} >
          <img className={wordData.id==i?"clicked":""} src={data.value} onClick={()=>handleClick(i)} height="70" width="100" alt="slider" />
        </div>
        )}
      </div>
    </div>
  );
}

export default ImageSlider;