import React from 'react'
import {Link} from 'react-router-dom'
import './CTA.css'
import front from '../assets/bar1.jpeg'
import Africa from '../assets/newConf.jpeg'
import Luxury from "../assets/pooitself.jpg"

import Bounce from 'react-reveal/Bounce'
import Flash from 'react-reveal/Flash'
import Zoom from 'react-reveal/Zoom'
const CTA = () => {
 
    return (
        <div className="home_display">
      
          <div className="all_Market">
        
            <aside className="events">
            <h1 className="header-center" >OUR DEDICATION</h1>
              <div className="cover-display">
              <div className="text-coded">
                <Zoom>
  
              <p style={{color:"#371D10"}} className="para-color" >
              The African Home has been one of the leading hotels in the hospitality industry for more than 10 years. Within these years It has consistently been of service to the nation through its patriotic nature. The services offered are as authentic as advertised, our facility is uniquely 
              built to give you a different perspective of a hotel in a city. It is non-conventional and built 
              to give you a feeling of a home away from home and out into the real Africa
                
                

                .</p>

               <p style={{color:"#371D10"}} className="para-color"> 
               Our zeal, dedication and commitment are the same as it was 10 years back, we strive to provide our clientele with services of the highest order. Our hotel is diverse and offers services
                to cater for all regardless of race, culture or background.
              </p>
       </Zoom>
          </div>
           <Flash> <img src={front} alt="business"/></Flash>
            
              <h1>Entertainment </h1>
             
              <p className="turn-right">
                A our unique African themed bar is absolutely stunning,
                we offer all sorts of alcoholic and non-alcoholic beverages,both local and international.Our bar is spacious and has
                 a sitting area that allows for wining and dining,
                 dancing space and casual chats.We also provide entertainment,through live music,live DJs and events.
              </p>
            <Link to="/about">  <button>Read More</button></Link>
              <br/>
             </div>
            </aside>
         <div className="aside-content">
                    <div className="first-history">
                    <Bounce right>
                      <aside className="first-history-detail">
                       
                      <img src={Africa} alt="read"/>
                           <h1 className="resize-h1"> Social Awareness</h1>
                           <p>The African Home is fully secured with 24 hrs surveillance and reserves the right of entry.
                           </p>
                      </aside>
                     </Bounce>
                     <Bounce left>
                      <aside className="first-history-detail">
                      <img src={Luxury} alt="luxury"/>
                           <h1 className="resize-h1">Leisure and Pleasure</h1>
                           <p>Proudly presents its main attraction,"The Cave".It is a state of the
                              art pool to entertain all our guests.The Cave can be used for 
                              hosting Galas dinners,family dinners,swimming and other functions.It simply caters for all your
                              occasions with style.
                           </p>
                      </aside>
                      </Bounce>
                    </div>
          </div>
          </div>
        </div>
    )
}

export default CTA
